
import { Component, Vue } from 'vue-property-decorator'

import { RequestPaging } from '@/utils/util'
import {
    apiIntegralGoodsCancel,
    apiIntegralGoodsConfirm,
    apiIntegralOrderLists
} from '@/api/application/integral_mall'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import OrderLogistics from '@/components/exchange-order/order-logistics.vue'
import DatePicker from '@/components/date-picker.vue'
@Component({
    components: {
        DatePicker,
        LsPagination,
        LsDialog,
        OrderLogistics
    }
})
export default class ExchangeOrder extends Vue {
    tabIndex = 0
    // 选项卡数据
    tabLists = [
        {
            status: '',
            label: '全部'
        },
        {
            status: 0,
            label: '待付款'
        },
        {
            status: 1,
            label: '待发货'
        },
        {
            status: 2,
            label: '待收货'
        },
        {
            status: 3,
            label: '已完成'
        },
        {
            status: 4,
            label: '已取消'
        }
    ]
    // 表单数据
    formData = {
        status: '', //列表状态:0-待付款 1-待发货 2-待收货 3-已发货 4-已取消
        sn: '', // 兑换单号
        goods_name: '', // 商品名称
        exchange_type: '', // 兑换类型:1-商品 2-红包
        start_time: '', //开始时间
        end_time: '', //开始时间
        sid:''
    }


  getTotalMoney () {
    return this.tabCount.total_money
  }

  getTotalExpressMoney () {
    return this.tabCount.total_express_money
  }


  tabCount = {
    total_money:0,
    total_express_money:0

  }
    // 分页
    pager = new RequestPaging()
    getList(page?: number): void {
        page && (this.pager.page = page)
        const status = this.tabLists[this.tabIndex].status
        this.pager.request({
            callback: apiIntegralOrderLists,
            params: {
                ...this.formData,
                status
            }
        }).then(res => {
          this.tabCount = res.extend
          console.log(res)
        })
    }
    // 重置
    handleReset() {
        Object.keys(this.formData).forEach(key => {
            this.$set(this.formData, key, '')
        })
        this.getList()
    }

    // 取消订单
    orderCancel(id: Number) {
        apiIntegralGoodsCancel({ id: id }).then(res => {
            this.getList()
        })
    }

    // 确认收货
    orderConfirm(id: Number) {
        apiIntegralGoodsConfirm({ id: id }).then(res => {
            this.getList()
        })
    }
    created() {
        this.getList()
    }
}
